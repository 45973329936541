import { InputRadioGroup, InputFilterable, InputText } from "@heart/components";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot("views.common.protected_tribe", {
  escapeJavascriptRoot: true,
});

/** A component to ask for ICWA details
 *
 * This can be used as either an uncontrolled Active Admin form input or
 * as a controlled input for React & GraphQL powered forms.
 */
const ProtectedTribes = ({
  anyTribeQuestionLabel,
  isMemberOfAnyTribe,
  tribeOptions,
  nonRecognizedTribeOptions,
  isRequired = false,
  isAfcarsRequired = false,
  // ⬇ provide these props for Active Admin ⬇
  tribesInputName,
  nonRecognizedTribesInputName,
  protectedTribeInputName,
  indianBloodCertNumberInputName,
  values,
  // ⬇ provide this prop for React/GraphQL ⬇
  onChange,
}) => {
  const [tribes, setTribes] = useState(values || {});
  const [isMemberOfTribe, setMemberOfTribe] = useState(isMemberOfAnyTribe);

  const setMembershipOfAny = e => {
    // If this is left blank, we just don't do anything
    // But if they are specifically set as not a member of any tribe, we set the other values to false
    // So we can continue even when those other fields are required
    // And also so we don't keep re-asking the same question
    if (e === false) {
      setMemberOfTribe(false);
      setTribes({ isProtectedTribeMember: false });
    } else {
      setMemberOfTribe(true);
    }
  };

  const setValueForField = inputId => newSectionValue =>
    inputId === "isProtectedTribeMember" && !newSectionValue
      ? setTribes({ [inputId]: newSectionValue })
      : setTribes({
          ...tribes,
          [inputId]: newSectionValue,
        });

  useEffect(() => {
    if (onChange) onChange(tribes);
  }, [onChange, tribes]);

  return (
    <div className="contains-inputs">
      <InputRadioGroup
        label={anyTribeQuestionLabel}
        onChange={setMembershipOfAny}
        required={isRequired}
        afcarsRequired={isAfcarsRequired}
        value={isMemberOfTribe}
        values={[
          { label: t("answer_yes"), value: true },
          { label: t("answer_no"), value: false },
        ]}
      />
      <InputRadioGroup
        label={t("tribal_membership_type")}
        onChange={setValueForField("isProtectedTribeMember")}
        required={isMemberOfTribe}
        hidden={!isMemberOfTribe}
        afcarsRequired={isAfcarsRequired}
        name={protectedTribeInputName}
        value={tribes.isProtectedTribeMember}
        values={[
          {
            label: t("federally_recognized_tribe"),
            value: true,
          },
          {
            label: t("non_federally_recognized_tribe"),
            value: false,
          },
        ]}
      />
      <InputFilterable
        label={t("federally_recognized_tribe_name")}
        afcarsRequired={isAfcarsRequired}
        isMulti
        required={tribes.isProtectedTribeMember}
        hidden={!tribes.isProtectedTribeMember}
        name={tribesInputName}
        onChange={setValueForField("tribeNames")}
        options={tribeOptions.map(tribe => ({
          label: tribe,
          value: tribe,
        }))}
        value={tribes.tribeNames || []}
      />
      <InputFilterable
        label={t("non_recognized_tribe_name")}
        isMulti
        required={!tribes.isProtectedTribeMember && isMemberOfTribe}
        name={nonRecognizedTribesInputName}
        hidden={!(tribes.isProtectedTribeMember === false) || !isMemberOfTribe}
        onChange={setValueForField("nonRecognizedTribeNames")}
        options={nonRecognizedTribeOptions.map(tribe => ({
          label: tribe,
          value: tribe,
        }))}
        value={tribes.nonRecognizedTribeNames || []}
      />
      <InputText
        label={t("indian_blood_certificate")}
        type="text"
        hidden={!isMemberOfTribe}
        name={indianBloodCertNumberInputName}
        value={tribes.tribeIdentification}
        onChange={setValueForField("tribeIdentification")}
      />
    </div>
  );
};

ProtectedTribes.propTypes = {
  /** The label for the first question */
  anyTribeQuestionLabel: PropTypes.string,
  /** A list of tribes with which to populate the dropdown */
  tribeOptions: PropTypes.array,
  /** A list of tribes with which to populate the dropdown */
  nonRecognizedTribeOptions: PropTypes.array,
  /** Whether these inputs should be required */
  isRequired: PropTypes.bool,
  /** Whether these inputs are required for AFCARS completeness */
  isAfcarsRequired: PropTypes.bool,
  /** Name of field, used for ActiveAdmin usecases */
  tribesInputName: PropTypes.string,
  /** Name of field, used for ActiveAdmin usecases */
  nonRecognizedTribesInputName: PropTypes.string,
  /** Name of field, used for ActiveAdmin usecases */
  protectedTribeInputName: PropTypes.string,
  /** Name of field, used for ActiveAdmin usecases */
  indianBloodCertNumberInputName: PropTypes.string,
  // Whether they are a member of a tribe currently
  isMemberOfAnyTribe: PropTypes.bool,
  /** Values for the inputs, used in conjunction with an onChange to make
   * this a component **controlled** by the component that renders ProtectedTribes
   */
  values: PropTypes.shape({
    isProtectedTribeMember: PropTypes.bool,
    tribeNames: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    nonRecognizedTribeNames: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    tribeIdentification: PropTypes.string,
  }),
  /** A function that takes in the name of the field being updated and returns a function
   * that will be called with the new value when the field is updated. Used when this
   * component is **controlled** by the component that renders ProtectedTribes
   */
  onChange: PropTypes.func,
};

export default ProtectedTribes;
